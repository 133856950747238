<template>
  <div>
    <van-swipe class="swipe-gxys" :loop="false" :show-indicators="false">
      <van-swipe-item>
        <img src="@/assets/gxys/1.jpg" />
      </van-swipe-item>
      <van-swipe-item>
        <div class="gxys-last">
          <span class="gxys-btn" @click="toMain">立即进入</span>
          <img src="@/assets/gxys/2.jpg" />
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant';

import { TO_MAIN } from '@/utils/Agreement';
export default {
  components: {
    VanSwipe: Swipe,
    VanSwipeItem: SwipeItem
  },
  data() {
    return {};
  },
  methods: {
    toMain() {
      window.location.href = TO_MAIN;
    }
  }
};
</script>

<style lang="less" scoped>
.swipe-gxys img {
  width: 100vw;
}

.swipe-gxys .gxys-last {
  position: relative;
}

.swipe-gxys .gxys-btn {
  position: absolute;
  bottom: 130px;
  left: 50%;
  width: 160px;
  margin-left: -80px;
  height: 40px;
  border-radius: 20px;
  background-color: #3967f0;
  color: white;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
